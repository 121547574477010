import React from "react";
import { BrowserRouter as Router, Routes, Route, useLocation } from "react-router-dom";
import Navbar from "./components/Navbar";
import Home from "./components/Home";
import About from "./components/About";
import Contact from "./components/Contact";
import TermsAndConditions from "./components/TermsAndConditions";
import Blog from "./components/Blog";
import Footer from "./components/Footer";
import PrivacyPolicy from "./components/PrivacyPolicy";
import Login from "./components/LoginSignup"; // Correct import for default export
import ProtectedRoute from "./components/ProtectedRoute";
import Explore from "./components/Explore";

const Layout: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const location = useLocation();
  const showNavbar = location.pathname !== "/explore";

  return (
    <>
      {showNavbar && <Navbar />}
      {children}
      <Footer />
    </>
  );
};

const App: React.FC = () => {
  return (
    <Router>
      <Layout>
        <Routes>
          {/* Public Routes */}
          <Route path="/" element={<Home />} />
          
          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          
          {/* Auth Routes */}
          <Route path="/login" element={<Login />} /> {/* Login route */}
          
          {/* Protected Route for Explore */}
          <Route
            path="/explore"
            element={
              <ProtectedRoute>
                <Explore />
              </ProtectedRoute>
            }
          />
          
          {/* Optional Routes */}
          {/* <Route path="/blog" element={<Blog />} /> */}
        </Routes>
      </Layout>
    </Router>
  );
};

export default App;
