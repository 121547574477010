import React, { useState } from "react";
import { db } from "../firebaseConfig"; // Adjust the import path as necessary
import { collection, addDoc } from "firebase/firestore";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Contact: React.FC = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    subject: "",
    message: "",
  });

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      await addDoc(collection(db, "contacts"), formData);
      toast.success("Message sent successfully!");
      setFormData({ name: "", email: "", subject: "", message: "" });
    } catch (error) {
      toast.error("Error submitting form.");
      console.error("Error submitting form: ", error);
    }
  };

  return (
    <div className="min-h-screen bg-gradient-to-b from-gray-900 via-black to-gray-800 text-white font-sans">
      <ToastContainer />
      <div className="container mx-auto px-6 lg:px-16 py-16 sm:mt-24 md:mt-2">
        <div className="grid lg:grid-cols-2 gap-12 items-center">
          {/* Left Section */}
          <div className="space-y-8">
            <h2 className="text-5xl font-extrabold tracking-tight text-yellow-400">
              Let’s Get in Touch!
            </h2>
            <p className="text-lg leading-relaxed text-gray-300">
              Whether you have a question, want to collaborate, or just want to
              say hi – we’re here! Share your ideas, and let’s work together
              towards a better, greener future.
            </p>
            <div className="space-y-4">
              <h3 className="text-2xl font-semibold text-yellow-400">
                Our Address
              </h3>
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3890.5379110531184!2d77.6333333!3d12.9141417!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae14100972bc39%3A0x1a24a5db7f69c6ed!2s1502%2C%2019th%20Main%20Rd%2C%201st%20Sector%2C%20HSR%20Layout%2C%20Bengaluru%2C%20Karnataka%20560102%2C%20India!5e0!3m2!1sen!2sus!4v1695115202567!5m2!1sen!2sus"
                width="100%"
                height="250"
                frameBorder="0"
                style={{ border: 0 }}
                allowFullScreen
                aria-hidden={false}
                tabIndex={0}
                title="Google Map of HSR Layout, Bengaluru"
                className="rounded-lg shadow-lg"
              ></iframe>
            </div>
            {/* New section for Address and CIN */}
            <div className="space-y-2">
              {/* <h3 className="text-xl font-semibold text-yellow-400">Our Address</h3> */}
              <p className="text-lg text-gray-300">
                Evolvevol Tech Pvt. Ltd.<br />
                1502, 19th Main Rd, 1st Sector,<br />
                HSR Layout, Bengaluru, Karnataka 560102
              </p>
              {/* <h3 className="text-xl font-semibold text-yellow-400">Corporate Information</h3> */}
              <p className="text-lg text-gray-300">
                CIN: U62020KA2024PTC194700
              </p>
            </div>
          </div>

          {/* Right Section */}
          <div className="bg-gray-800 p-10 rounded-3xl shadow-2xl border border-gray-700 my-16">
            <h3 className="text-4xl font-bold text-center mb-8 text-yellow-400">
              Drop Us a Message
            </h3>
            <form onSubmit={handleSubmit} className="space-y-6">
              <div className="relative">
                <input
                  type="text"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  placeholder="Your Name"
                  className="w-full py-4 px-6 bg-gray-700 border border-gray-600 rounded-full text-gray-300 placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-yellow-500"
                  required
                />
              </div>
              <div className="relative">
                <input
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  placeholder="Your Email"
                  className="w-full py-4 px-6 bg-gray-700 border border-gray-600 rounded-full text-gray-300 placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-yellow-500"
                  required
                />
              </div>
              <div className="relative">
                <input
                  type="text"
                  name="subject"
                  value={formData.subject}
                  onChange={handleChange}
                  placeholder="Subject"
                  className="w-full py-4 px-6 bg-gray-700 border border-gray-600 rounded-full text-gray-300 placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-yellow-500"
                  required
                />
              </div>
              <div className="relative">
                <textarea
                  name="message"
                  value={formData.message}
                  onChange={handleChange}
                  placeholder="Your Message"
                  rows={4}
                  className="w-full py-4 px-6 bg-gray-700 border border-gray-600 rounded-lg text-gray-300 placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-yellow-500"
                  required
                ></textarea>
              </div>
              <button
                type="submit"
                className="w-full py-3 rounded-full bg-yellow-500 text-black font-semibold text-lg transition duration-300 hover:bg-yellow-600"
              >
                Send Message
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
