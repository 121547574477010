import React, { useState, useEffect } from "react";
import { db, auth } from "../firebaseConfig"; // Importing Firebase auth and Firestore
import { collection, addDoc } from "firebase/firestore";
import { createUserWithEmailAndPassword } from "firebase/auth";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Track from "../Image/track.png";
import Earn from "../Image/earn.png";
import Earth from "../Image/earth.png";
import coin from "../Image/reker_coin.png";
import FoundersHub from "../Image/Microsoft.png";
import Microsoft from "../Image/nvidia.png";

const ImageSection: React.FC = () => {
  const myVideo = require("../video/Second_frame.mp4");

  return (
    <section className="relative w-full h-screen bg-black">
      <video
        className="absolute top-0 left-0 w-full h-full object-contain z-0"
        src={myVideo}
        autoPlay
        muted
        loop
        playsInline
      />
      <div className="relative z-10 flex flex-col items-center justify-center text-center px-6 py-32">
        <h1 className="text-5xl md:text-6xl font-extrabold text-white leading-tight mb-6">
          Welcome to <span className="text-green-400">Reker</span>
        </h1>
        <p className="text-lg md:text-2xl text-gray-300 font-medium leading-relaxed max-w-2xl">
          India’s first <span className="text-green-400">AR-Gamified</span>{" "}
          rewarding platform
        </p>
      </div>
      <div className="absolute inset-0 bg-gradient-to-b from-transparent to-black opacity-20"></div>
    </section>
  );
};

const features = [
  {
    img: Track,
    title: "Track Your Commute",
    desc: "Automatically track your eco-friendly commutes with ease.",
  },
  {
    img: Earn,
    title: "Earn Rewards",
    desc: "Get Reker credits and rewards for making sustainable choices.",
  },
  {
    img: Earth,
    title: "Support the Planet",
    desc: "Help reduce carbon emissions and make a difference.",
  },
];

const Home: React.FC = () => {
  const [showModal, setShowModal] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    mobile: "",
    email: "",
    location: "",
  });
  const [showOverlay, setShowOverlay] = useState(false);

  useEffect(() => {
    const handleScroll = () => setShowOverlay(true);
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        formData.email,
        "temporaryPassword"
      );

      const user = userCredential.user;

      await addDoc(collection(db, "waitlist"), {
        uid: user.uid,
        name: formData.name,
        mobile: formData.mobile,
        location: formData.location,
        email: formData.email,
      });

      toast.success("You have successfully joined the waitlist!");
      setShowModal(false);
      setFormData({ name: "", mobile: "", email: "", location: "" });
    } catch (error) {
      console.error("Error adding document: ", error);
      toast.error("An error occurred. Please try again.");
    }
  };

  const handleWaitlistClick = () => {
    setShowModal(true);
  };

  return (
    <div className="bg-gray-900 text-gray-100">
      <ToastContainer />
      <main>
        <ImageSection />

        <section className="py-16 bg-gray-900">
          <div className="max-w-7xl mx-auto px-4">
            <h2 className="text-3xl md:text-4xl font-bold text-center mb-12">
              <span className="text-green-400">Explore Our Features</span>
            </h2>
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-12">
              {features.map(({ img, title, desc }, idx) => (
                <div
                  key={idx}
                  className="bg-gray-800 rounded-xl shadow-lg hover:shadow-xl transition-transform transform hover:scale-105 p-6 text-center"
                >
                  <div className="w-20 h-20 mx-auto mb-4 bg-gray-700 rounded-full flex items-center justify-center overflow-hidden">
                    <img
                      src={img}
                      alt={title}
                      className="w-full h-full object-cover"
                    />
                  </div>
                  <h3 className="text-lg font-semibold text-gray-100 mb-2">
                    {title}
                  </h3>
                  <p className="text-gray-400 text-sm">{desc}</p>
                </div>
              ))}
            </div>
          </div>
        </section>

        <section className="py-20 bg-gradient-to-b from-gray-900 to-gray-800">
          <div className="max-w-5xl mx-auto text-center px-6">
            <h2 className="text-2xl md:text-3xl font-bold mb-8 text-green-400">
              Earn Personal Carbon Credits & Reker Coins
            </h2>
            <p className="text-gray-400 mb-6 max-w-3xl mx-auto">
              Commute using eco-friendly modes like metro, walking, EVs, and
              buses to save the environment while earning rewards.
            </p>
            <img
              src={coin}
              alt="Carbon Credits"
              className="mx-auto w-64 rounded-lg shadow-lg"
            />
          </div>
        </section>

        <section className="py-20 bg-gray-800">
          <div className="max-w-5xl mx-auto text-center px-6">
            <h2 className="text-2xl md:text-3xl font-bold mb-6">
              We’re now backed by{" "}
              <span className="text-green-400">Microsoft & NVIDIA</span>
            </h2>
            <p className="text-gray-400 leading-relaxed mb-8 max-w-2xl mx-auto">
              This milestone drives us closer to revolutionizing sustainability
              through AR gamification. 🌍 Stay connected as we embark on the
              next chapter!
            </p>
            <div className="flex justify-center flex-wrap gap-8">
              <img
                src={FoundersHub}
                alt="Founders Hub"
                className="w-40 md:w-56 object-contain"
              />
              <img
                src={Microsoft}
                alt="Microsoft"
                className="w-40 md:w-56 object-contain"
              />
            </div>
          </div>
        </section>

        <section className="relative w-full h-screen flex items-center justify-center bg-black">
          <video
            className="absolute top-0 left-0 w-full h-full object-cover z-0"
            src={require("../video/my_video1.mp4")}
            autoPlay
            muted
            loop
            playsInline
          />
          <div className="relative z-10 text-center px-4">
            <h2 className="text-3xl md:text-4xl font-bold text-gray-100 mb-6">
              World’s first Climate Combative Lifestyle App
            </h2>
            <button
              onClick={handleWaitlistClick}
              className="px-10 py-4 bg-gradient-to-r from-teal-400 to-blue-500 text-white font-semibold rounded-full shadow-lg hover:shadow-xl transform hover:scale-105"
            >
              Join the Waitlist
            </button>
          </div>
          <div className="absolute inset-0 bg-gradient-to-b from-transparent to-black opacity-60"></div>
        </section>
      </main>

      {showModal && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-70">
          <div className="bg-white p-8 rounded-lg max-w-sm w-full">
            <h2 className="text-2xl font-bold text-center text-black mb-4">Join the Waitlist</h2>
            <form onSubmit={handleSubmit}>
              <div className="mb-4">
                <label className="block text-gray-700">Name</label>
                <input
                  type="text"
                  name="name"
                  value={formData.name}
                  onChange={handleInputChange}
                  className="w-full p-2 border rounded text-black"
                  required
                />
              </div>

              <div className="mb-4">
                <label className="block text-gray-700">Mobile Number</label>
                <input
                  type="tel"
                  name="mobile"
                  value={formData.mobile}
                  onChange={handleInputChange}
                  className="w-full p-2 border rounded text-black"
                  required
                />
              </div>
              <div className="mb-4">
                <label className="block text-gray-700">Email</label>
                <input
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={handleInputChange}
                  className="w-full p-2 border rounded text-black"
                  required
                />
              </div>
              <div className="mb-4">
                <label className="block text-gray-700">Location</label>
                <input
                  type="text"
                  name="location"
                  value={formData.location}
                  onChange={handleInputChange}
                  className="w-full p-2 border rounded text-black"
                  required
                />
              </div>
              <button
                type="submit"
                className="w-full bg-teal-500 text-white py-2 rounded"
              >
                Submit
              </button>
            </form>
            <button
              onClick={() => setShowModal(false)}
              className="mt-4 w-full text-center text-gray-500 hover:text-gray-700"
            >
              Close
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Home;
