import React, { useState, FormEvent } from "react";
import { useNavigate } from "react-router-dom";
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
} from "firebase/auth";
import { auth } from "../firebaseConfig";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FaEye, FaEyeSlash, FaEnvelope, FaLock } from "react-icons/fa";
import LogoCarousel from "./ExploreCarousel";
import myGif from "../video/gif.gif";

const LoginSignup: React.FC = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [isLogin, setIsLogin] = useState(true);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const togglePasswordVisibility = () => setShowPassword(!showPassword);

  const handleAuth = async (e: FormEvent) => {
    e.preventDefault();
    setLoading(true);

    try {
      if (isLogin) {
        await signInWithEmailAndPassword(auth, email, password);
        toast.success("Login successful");
        navigate("/explore");
      } else {
        await createUserWithEmailAndPassword(auth, email, password);
        toast.success("Account created successfully");
        setIsLogin(true);
      }
    } catch (error: any) {
      toast.error(error.message || "An error occurred");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="min-h-screen bg-gray-900 text-white font-sans">
      {/* Header Section */}
      <header className="flex flex-col md:flex-row items-center justify-between px-8 md:px-16 py-16 bg-gradient-to-r from-black to-gray-900">
        <div className="md:w-1/2 text-center md:text-left space-y-6">
          <h1 className="text-4xl md:text-5xl lg:text-6xl font-extrabold leading-snug">
            Engage. Reward. Retain.
          </h1>
          <p className="text-lg md:text-xl lg:text-2xl text-gray-300 leading-relaxed">
            Transform engagement through next-gen AR-driven rewards.
          </p>
          <button
            onClick={() => window.scrollTo(0, window.innerHeight)}
            className="px-8 py-4 mt-6 bg-yellow-500 text-black rounded-lg font-medium hover:bg-yellow-600 transition"
          >
            Learn More
          </button>
        </div>
        <div className="mt-8 md:mt-0 md:w-1/2 flex justify-center">
          <img
            src={myGif}
            alt="Interactive AR engagement"
            className="rounded-lg shadow-lg w-full max-w-lg md:max-w-xl"
          />
        </div>
      </header>

      {/* Authentication Section */}
      <main className="max-w-7xl mx-auto px-6 md:px-12 py-16 flex flex-col lg:flex-row gap-12 items-center">
        {/* Left Content */}
        <div className="lg:w-1/2 space-y-8">
          <Section
            title="Seamless Loyalty Program Integration"
            description="Create a seamless experience with an integrated loyalty program across online and offline channels, allowing customers to earn and redeem rewards effortlessly."
          />
          <Section
            title="Say Goodbye to Boring Rewards!"
            description="Meet RkR Coins: The new-age digital currency that combines loyalty points, instant cashback, and special offers for every purchase you make."
          />
          <Section
            title="Gamify Marketing and Advertising Strategies"
            description="Use the Reker World platform to drive your customers back for more. Engage them with exclusive AR experiences that keep them excited and loyal."
          />
        </div>

        {/* Right Form */}
        <div className="lg:w-1/2 bg-gray-800 p-10 rounded-2xl shadow-xl border border-gray-700">
          <ToastContainer />
          <h2 className="text-3xl md:text-4xl font-bold text-center">
            {isLogin ? "Log In" : "Sign Up"}
          </h2>
          <form onSubmit={handleAuth} className="space-y-6 mt-6">
            <InputField
              icon={<FaEnvelope />}
              type="email"
              placeholder="Email Address"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <InputField
              icon={<FaLock />}
              type={showPassword ? "text" : "password"}
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              toggleVisibility={togglePasswordVisibility}
              showPassword={showPassword}
            />
            <button
              type="submit"
              disabled={loading}
              className={`w-full py-3 rounded-full bg-yellow-500 text-black font-semibold transition ${
                loading ? "opacity-50 cursor-not-allowed" : "hover:bg-yellow-600"
              }`}
            >
              {loading ? "Processing..." : isLogin ? "Log In" : "Sign Up"}
            </button>
            {isLogin && (
              <p className="text-center text-gray-400 mt-4 text-sm">
                Don’t have an account? Reach us at{" "}
                <a
                  href="mailto:brands@reker.world"
                  className="text-yellow-500 hover:underline"
                >
                  brands@reker.world
                </a>
              </p>
            )}
          </form>
        </div>
      </main>

      {/* Carousel Section */}
      <footer className="bg-gray-800 py-8">
        <div className="max-w-6xl mx-auto">
          <LogoCarousel />
        </div>
      </footer>
    </div>
  );
};

// Reusable Components
const Section: React.FC<{ title: string; description: string }> = ({
  title,
  description,
}) => (
  <div className="p-6 rounded-lg bg-gray-700 hover:border-2 hover:border-yellow-500 transition">
    <h3 className="text-xl md:text-2xl font-bold text-yellow-400 hover:text-yellow-500">
      {title}
    </h3>
    <p className="mt-2 text-gray-300 text-sm md:text-lg">{description}</p>
  </div>
);

const InputField: React.FC<{
  icon: React.ReactNode;
  type: string;
  placeholder: string;
  value: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  toggleVisibility?: () => void;
  showPassword?: boolean;
}> = ({
  icon,
  type,
  placeholder,
  value,
  onChange,
  toggleVisibility,
  showPassword,
}) => (
  <div className="relative">
    <span className="absolute left-4 top-1/2 transform -translate-y-1/2 text-gray-400">
      {icon}
    </span>
    <input
      type={type}
      placeholder={placeholder}
      value={value}
      onChange={onChange}
      className="w-full pl-12 py-3 rounded-full bg-gray-700 border border-gray-600 focus:ring-2 focus:ring-yellow-500 text-gray-300"
      required
    />
    {toggleVisibility && (
      <button
        type="button"
        onClick={toggleVisibility}
        className="absolute right-4 top-1/2 transform -translate-y-1/2 text-gray-400 hover:text-gray-200"
      >
        {showPassword ? <FaEyeSlash /> : <FaEye />}
      </button>
    )}
  </div>
);

export default LoginSignup;
